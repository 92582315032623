var testManagementModule = angular.module('t2g.testManagement', [
  'gettext',
  'ui.router',
  require('t2g.common.services.modalService').name,
  require('t2g.common.services.notificationService').name
]);

module.exports = testManagementModule;

testManagementModule.controller('TestManagementCtrl', require('./TestManagementCtrl'));
testManagementModule.controller('ScanQRModalCtrl', require('./ScanQRModalCtrl'));
testManagementModule.controller('TestLayoverCtrl', require('./TestLayoverCtrl'));

testManagementModule.config(function ($stateProvider) {
  $stateProvider
    .state('private.main.testManagement', {
      url: '/test-management/?:openTab&testId&customerId&date',
      params: {
        openTab: {
          dynamic: true
        }
      },
      title: 'Test Management',
      templateUrl: 'https://cdn.termin2go.com/app/app/modules/testManagement/TestManagementView.html?v=3.80.3',
      controller: 'TestManagementCtrl as TestManagementCtrl',
      resolve: {
        tests($stateParams, TestDA) {
          var date = $stateParams.date ? $stateParams.date : moment().format('YYYY-MM-DD');
          if (!$stateParams.openTab || $stateParams.openTab === 'upcoming') {
            return TestDA.getUpcoming(date);
          } else if($stateParams.openTab === 'inProgress') {
            return TestDA.getInProgress();
          } else {
            return TestDA.getDone(date);
          }
        },
        office: function (OfficeDA) {
          return OfficeDA.get();
        }
      }
    });
});
