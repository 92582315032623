'use strict';

var branchesModule = angular.module('t2g.branches', [
  'gettext',
  'ui.router',
  require('t2g.common.services.notificationService').name,
  require('t2g.common.services.modalService').name
]);

module.exports = branchesModule;

branchesModule.controller('BranchesCtrl', require('./BranchesCtrl'));

branchesModule.config(function ($stateProvider) {
  $stateProvider
    .state('private.main.branches', {
      url: '/branches',
      title: 'Branches',
      templateUrl: 'https://cdn.termin2go.com/app/app/modules/branches/BranchesView.html?v=3.80.3',
      controller: 'BranchesCtrl as BranchesCtrl',
      resolve: {
        branches: function (BranchDA) {
          return BranchDA.list();
        },
        subscription: function (SubscriptionDA) {
          return SubscriptionDA.get();
        },
        companyGroupSettings: function (BranchDA) {
          return BranchDA
            .getCompanyGroupSettings()
            .then((response) => {
              return response.companyGroupSettings;
            })
        },
      }
    });
});

