'use strict';

var pageWidgetModule = angular.module('t2g.pageWidget', [
    'gettext',
    'ui.router',
    'ngSanitize',
    require('t2g.common.services.notificationService').name
]);

module.exports = pageWidgetModule;

pageWidgetModule.controller('PageWidgetCtrl', require('./pageWidgetCtrl'));

pageWidgetModule.config(function($stateProvider) {
    $stateProvider
        .state('private.main.pageWidget', {
            url: '/page-widget',
            title: 'Booking widget website',
            templateUrl: 'https://cdn.termin2go.com/app/app/modules/pageWidget/PageWidgetView.html?v=3.80.3',
            controller: 'PageWidgetCtrl as PageWidgetCtrl',
            resolve: {
                office: function(OfficeDA) {
                    return OfficeDA.get();
                }
            }
        });
});
