module.exports = function ($q,
                           $scope,
                           $state,
                           $stateParams,
                           officeResolve,
                           userResolve,
                           OfficeDA,
                           covidTests,
                           Notification,
                           IndustrySpecific,
                           gettextCatalog,
                           settings,
                           employees,
                           AccountingDA,
                           PaymentDA,
                           MailDA,
                           UserSession) {
  $scope.isAdmin = UserSession.checkRole('admin');
  $scope.covidTests = covidTests;
  $scope.settingsForm = settings.settingsForm;
  $scope.office = officeResolve;
  $scope.users = userResolve;
  $scope.openTab = $stateParams.openTab;
  $scope.showPremiumNotification = ($scope.subscription.onlyBasicFunctions || $scope.subscription.package === 'single');
  $scope.isPackageIsSchnelltest = ['schnelltest', 'schnelltest2', 'schnelltest_archiv'].includes($scope.subscription.package);
  $scope.isNotPackage = (subPackage) => subPackage !== $scope.subscription.package;
  $scope.connectingHellocash = false;
  $scope.currencies = [
    'EUR',
    'CHF',
    'EGP'
  ];
  $scope.slotStarts = [
    { value: 60, label: gettextCatalog.getString('Every full hour') },
    { value: 30, label: gettextCatalog.getString('Every 30 Minutes') },
    { value: 15, label: gettextCatalog.getString('Every 15 Minutes') },
    { value: 10, label: gettextCatalog.getString('Every 10 Minutes') },
    { value: 5, label: gettextCatalog.getString('Every 5 Minutes') },
    { value: 1, label: gettextCatalog.getString('Every Minute') }
  ];
  $scope.paymentsMethods = [
    { value: 'apple', label: 'Apple Pay' },
    { value: 'cash', label: gettextCatalog.getString('Cash') },
    { value: 'ec', label: gettextCatalog.getString('EC-Card') },
    { value: 'es', label: gettextCatalog.getString('Einzahlungsschein') },
    { value: 'cc', label: gettextCatalog.getString('Credit Card') },
    { value: 'dd', label: gettextCatalog.getString('Direct Debit') },
    { value: 'coupon', label: gettextCatalog.getString('Coupon') },
    { value: 'pp', label: 'PayPal' },
    { value: 'pc', label: gettextCatalog.getString('Post-Card') },
    { value: 'invoice', label: gettextCatalog.getString('Invoice') },
    { value: 'twint', label: gettextCatalog.getString('TWINT') },
  ];

  $scope.calendarZooms = [
    { value: 1, label: '1 (100%)' },
    { value: 2, label: '2 (200%)' },
    { value: 3, label: '3 (300%)' },
    { value: 4, label: '4 (400%)' },
    { value: 5, label: '5 (500%)' },
  ];

  IndustrySpecific.getLabels().then(function (labels) {
    $scope.customersLabel = gettextCatalog.getString(labels.customers.toLowerCase());
  });

  $scope.smsCharsLeft = 0;
  $scope.smsCharsMaxLen = 0;

  $scope.calculateSMSLength = function () {
    let difference = 0;
    const placeholderMap = {
      date: 10,
      dateFormatted: Math.max(gettextCatalog.getString('today').length, 10),
      time: 5,
      office: $scope.office.name ? $scope.office.name.length : 0
    };

    const smsText = _.get($scope.office, 'settings.reminder.sms.text', 'Erinnerung: {dateFormatted}, {time} bei {office}. Bitte nicht auf diese SMS antworten.');
    let textLen = smsText.length;

    Object
      .keys(placeholderMap)
      .forEach((placeholderKey) => {
        if (smsText.includes(`{${placeholderKey}}`)) {
          textLen -= (placeholderKey.length + 2);
          textLen += placeholderMap[placeholderKey];
          difference += (placeholderKey.length + 2) - placeholderMap[placeholderKey];
        }
      });

    $scope.smsCharsMaxLen = 160 + difference;
    $scope.smsCharsLen = textLen;
  };

  $scope.calculateSMSLength();


  function generateResourceOrder () {
    var alreadySorted = [];
    $scope.employees = [];

    // add sorted resources
    _.forEach($scope.office.settings.resourceOrder, function (employeeId) {
      var employee = _.find(employees, { _id: employeeId });

      if (employee) {
        $scope.employees.push(employee);
      }

      alreadySorted.push(employeeId);
    });

    // add non-sorted resources
    _.forEach(employees, function (employee) {
      if (alreadySorted.indexOf(employee._id) === -1) {
        $scope.employees.push(employee);
        alreadySorted.push(employee._id);
      }
    });
  }

  function getResourceOrder () {
    $scope.office.settings.resourceOrder = _.map($scope.employees, '_id');
  }

  function moveEmployee (index, direction) {
    $scope.employees.move(index, index + direction);
    getResourceOrder();
  }

  $scope.moveEmployeeUp = function (index) {
    if (index >= 1) {
      moveEmployee(index, -1);
    }
  };

  $scope.moveEmployeeDown = function (index) {
    if (index <= $scope.employees.length - 1) {
      moveEmployee(index, 1);
    }
  };

  $scope.switchTab = function (tab) {
    $state.transitionTo('private.main.settings', {
      openTab: tab
    }, { notify: false, reloadOnSearch: false });
  }

  // generate lead hour values
  $scope.leadTimes = [];
  var leadMinutes = [
    { minutes: 10, value: 0.006944444444444 },
    { minutes: 15, value: 0.010416666666667 },
    { minutes: 20, value: 0.013888888888889 },
    { minutes: 30, value: 0.020833333333333 },
    { minutes: 45, value: 0.03125 },
  ];

  var leadHours = [
    { hours: 1, value: 0.0416666666666667 },
    { hours: 2, value: 0.0833333333333333 },
    { hours: 3, value: 0.125 },
    { hours: 4, value: 0.1666666666666667 },
    { hours: 6, value: 0.25 },
    { hours: 8, value: 0.3333 },
    { hours: 10, value: 0.4167 },
    { hours: 12, value: 0.5 }
  ];

  var leadDays = [
    { days: 1, value: 1 },
    { days: 2, value: 2 },
    { days: 3, value: 3 },
    { days: 4, value: 4 },
    { days: 5, value: 5 },
    { days: 6, value: 6 },
    { days: 7, value: 7 },
    { days: 10, value: 10 }
  ];

  leadMinutes.forEach(function (leadMinute) {
    $scope.leadTimes.push({
      label: gettextCatalog.getString('{{count}} minutes', { count: leadMinute.minutes }),
      value: leadMinute.value
    });
  });

  leadHours.forEach(function (leadHour) {
    $scope.leadTimes.push({
      label: gettextCatalog.getPlural(leadHour.hours, '1 hour', '{{$count}} hours', {}),
      value: leadHour.value
    });
  });

  leadDays.forEach(function (leadDay) {
    $scope.leadTimes.push({
      label: gettextCatalog.getPlural(leadDay.days, '1 day', '{{$count}} days', {}),
      value: leadDay.value
    });
  });

  // generate book advance values
  var bookAdvanceTimes = [1, 3, 7, 14, 21, 28, 45, 60, 90, 120, 180, 270];
  $scope.bookAdvanceTimes = [];

  bookAdvanceTimes.forEach(function (bookAdvanceTime) {
    $scope.bookAdvanceTimes.push({
      label: gettextCatalog.getPlural(bookAdvanceTime, '1 day', '{{$count}} days', {}),
      value: bookAdvanceTime
    });
  });


  // generate accept after values
  var acceptAfterTimes = [1, 2, 3, 6, 12, 24];

  $scope.acceptAfter = [
    {
      label: gettextCatalog.getString('Instant'),
      hours: 0
    },
    {
      label: gettextCatalog.getString('After half an hour'),
      hours: 0.5
    }
  ];

  acceptAfterTimes.forEach(function (acceptAfterTime) {
    $scope.acceptAfter.push({
      label: gettextCatalog.getPlural(acceptAfterTime, 'After 1 hour', 'After {{$count}} hours', {}),
      hours: acceptAfterTime
    });
  });

  $scope.serviceGroupCollapsingSettings = [
    { value: 0, label: gettextCatalog.getString('First group opened (default)') },
    { value: 1, label: gettextCatalog.getString('All expanded') },
    { value: 2, label: gettextCatalog.getString('All collapsed') }
  ];

  $scope.office.settings.widget = $scope.office.settings.widget || {};
  $scope.office.settings.widget.appointment = $scope.office.settings.widget.appointment || {};
  $scope.office.settings.widget.appointment.serviceGroupCollapsing = $scope.office.settings.widget.appointment.serviceGroupCollapsing || 0;

  // generate reminder times
  var reminderTimesHours = [
    { hours: 1, value: 3600 },
    { hours: 2, value: 7200 },
    { hours: 3, value: 10800 },
    { hours: 5, value: 18000 },
    { hours: 8, value: 28800 },
    { hours: 12, value: 43200 }
  ];

  var reminderTimesDays = [
    { days: 1, value: 86400 },
    { days: 2, value: 172800 },
    { days: 3, value: 259200 },
    { days: 5, value: 432000 },
    { days: 7, value: 604800 }
  ];

  $scope.reminderTimes = [
    {
      label: gettextCatalog.getString('Day before at 8 p.m'),
      value: -1
    },
    {
      label: gettextCatalog.getString('30 minutes before'),
      value: 1800
    }
  ];

  reminderTimesHours.forEach(function (reminderTimesHour) {
    $scope.reminderTimes.push({
      label: gettextCatalog.getPlural(reminderTimesHour.hours, '1 hour before', '{{$count}} hours before', {}),
      value: reminderTimesHour.value
    });
  });

  reminderTimesDays.forEach(function (reminderTimesDay) {
    $scope.reminderTimes.push({
      label: gettextCatalog.getPlural(reminderTimesDay.days, '1 day before', '{{$count}} days before', {}),
      value: reminderTimesDay.value
    });
  });


  // generate cancellation limit values
  var cancellationLimitDays = [
    { days: 2, value: 172800000 },
    { days: 3, value: 259200000 },
    { days: 4, value: 345600000 },
    { days: 5, value: 432000000 }
  ];

  $scope.cancellationLimit = [
    { label: gettextCatalog.getString('always'), value: 0 },
    { label: gettextCatalog.getPlural(1, '1 hour before', '{{$count}} hours before', {}), value: 3600000 },
    { label: gettextCatalog.getPlural(2, '1 hour before', '{{$count}} hours before', {}), value: 7200000 },
    { label: gettextCatalog.getPlural(4, '1 hour before', '{{$count}} hours before', {}), value: 14400000 },
    { label: gettextCatalog.getPlural(8, '1 hour before', '{{$count}} hours before', {}), value: 28800000 },
    { label: gettextCatalog.getPlural(12, '1 hour before', '{{$count}} hours before', {}), value: 43200000 },
    { label: gettextCatalog.getPlural(24, '1 hour before', '{{$count}} hours before', {}), value: 86400000 }
  ];

  cancellationLimitDays.forEach(function (cancellationLimitDay) {
    $scope.cancellationLimit.push({
      label: gettextCatalog.getPlural(cancellationLimitDay.days, '1 day before', '{{$count}} days before', {}),
      value: cancellationLimitDay.value
    });
  });

  $scope.maxServices = [1, 2, 3, 4, 5];

  $scope.office.settings.contextMenu = $scope.office.settings.contextMenu || {
    single: { active: true, label: gettextCatalog.getString('Appointment') },
    course: { active: true, label: gettextCatalog.getString('Course') },
    private: { active: true, label: gettextCatalog.getString('Private') }
  };

  $scope.office.settings.cancelLimit = $scope.office.settings.cancelLimit || 0;
  $scope.office.settings.slotLength = $scope.office.settings.slotLength || 15;
  $scope.office.settings.slotStart = $scope.office.settings.slotStart || 60;

  // reminder email time default
  if (!$scope.$eval('office.settings.reminder.email.time')) {
    $scope.$eval('office.settings.reminder.email.time=-1');
  }

  if (!$scope.$eval('office.settings.reminder.sms.time')) {
    $scope.$eval('office.settings.reminder.sms.time=-1');
  }

  // global mail address default
  if (!$scope.$eval('office.settings.email.global.address')) {
    $scope.$eval('office.settings.email.global.address=office.profile.contacts.email');
  }

  $scope.$watch('office.settings.covidTest.covidPrivacyTermsEnabled', function (covidPrivacyTermsEnabled, oldValue) {
    if (covidPrivacyTermsEnabled && !oldValue) {
      $scope.office.name = $scope.office.name || '';
      $scope.office.address = $scope.office.address || {};
      $scope.office.address.street = $scope.office.address.street || '';
      $scope.office.address.postal = $scope.office.address.postal || '';
      $scope.office.address.city = $scope.office.address.city || {};
      $scope.office.address.city.name = $scope.office.address.city.name || '';
    }
  });

  /**
   * @param type
   */
  $scope.reminderChanged = function (type) {
    if ($scope.office.settings.reminder[type].time === -1) {
      $scope.office.settings.reminder[type].dayBefore = true;
    } else {
      $scope.office.settings.reminder[type].dayBefore = false;
    }
  };

  /**
   * save to API
   */
  $scope.save = function () {
    $scope.showValidation = true;

    if ($scope.SettingsForm.$valid) {
      OfficeDA
        .update($scope.office)
        .then(() => {
          Notification.addSuccessMessage(gettextCatalog.getString('Settings saved'));
        })
        .catch((error) => {
          Notification.addErrorMessage({ text: error });
        });
    }
  };

  $scope.testSMTP = function () {
    $scope.testingSMTP = true;

    if ($scope.areSMTPFieldsValid) {
      MailDA
        .verifySMTP($scope.office.settings.email.smtp)
        .then(function () {
          $scope.testingSMTP = false;
          Notification.addSuccessMessage(gettextCatalog.getString('Connection OK.'));
        })
        .catch(function () {
          $scope.testingSMTP = false;
          Notification.addErrorMessage(gettextCatalog.getString('Error: Cant connect to SMTP Server. Please check Settings or contact Support.'));
        });
    }
  };

  $scope.areSMTPFieldsValid = function () {
    return _.get($scope.office.settings, 'email.smtp.from') &&
      _.get($scope.office.settings, 'email.smtp.options.host') &&
      _.get($scope.office.settings, 'email.smtp.options.auth.user') &&
      _.get($scope.office.settings, 'email.smtp.options.auth.pass');
  };

  $scope.connectHelloCash = function () {
    const username = _.get($scope.office.settings, 'accounting.helloCash.username');
    const password = _.get($scope.office.settings, 'accounting.helloCash.password');
    $scope.connectingHellocash = true;

    AccountingDA
      .connectToHelloCash(username, password)
      .then(function () {
        $scope.connectingHellocash = false;
        $scope.office.isHelloCashActivated = true;
        Notification.addSuccessMessage(gettextCatalog.getString('Connection OK.'));
      })
      .catch(function () {
        $scope.connectingHellocash = false;
        Notification.addErrorMessage(gettextCatalog.getString('Error: Cant connect to HelloCash. Please check your settings or contact Support.'));
      });
  };

  $scope.disconnectHelloCash = function () {
    $scope.connectingHellocash = true;

    AccountingDA
      .disconnectHelloCash()
      .then(function () {
        $scope.connectingHellocash = false;
        $scope.office.isHelloCashActivated = false;
        Notification.addSuccessMessage(gettextCatalog.getString('Disconnected from HelloCash.'));
      })
      .catch(function () {
        $scope.connectingHellocash = false;
        Notification.addErrorMessage(gettextCatalog.getString('Error: Cant connect to HelloCash. Please check your settings or contact Support.'));
      });
  };

  $scope.connectStripe = function (event) {
    event.preventDefault();

    PaymentDA
      .getStripeOAuthLink()
      .then((linkResponse) => {
        window.location = linkResponse.link;
      })
      .catch((error) => {
        Notification.addErrorMessage({ text: error });
      });
  };

  $scope.deauthorizeStripe = function (event) {
    event.preventDefault();

    PaymentDA
      .deauthorizeStripe()
      .then((linkResponse) => {
        console.log(linkResponse);
      })
      .catch((error) => {
        Notification.addErrorMessage({ text: error });
      });
  };

  generateResourceOrder();
};
