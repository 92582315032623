'use strict';

var angularModule = angular.module('t2g.common.services.languageSwitch', [
  'gettext',
  require('t2g.common.data-access.user').name,
  require('t2g.common.data-access.resource').name,
  require('t2g.common.services.notificationService').name,
  require('t2g.common.services.userSessionService').name
]);

module.exports = angularModule;

angularModule.factory('languageSwitch', function(gettextCatalog,
                                                 Notification,
                                                 UserDA,
                                                 UserSession,
                                                 settings) {

  let selectedLanguage;

  return {
    set,
    init
  };

  function _load(languageCode) {

    return gettextCatalog
      .loadRemote('/i18n/' + languageCode + '.json?v=3.80.3')
      .then(function() {
        _.forEach(settings.availableLanguages, function(lang) {
          var i18nOverwrite = _.get(settings, 'i18nOverwrite.' + lang);
          if (i18nOverwrite) {
            gettextCatalog.setStrings(lang, i18nOverwrite);
          }
        });
        gettextCatalog.setCurrentLanguage(languageCode);
        return true;
      });
  }

  function set(languageCode, doNotUpdateUser) {
    if (settings.availableLanguages.indexOf(languageCode) === -1) {
      languageCode = 'de';
    }

    selectedLanguage = languageCode;

    if (!doNotUpdateUser) {
      UserDA
        .updateAccount({
          language: languageCode
        })
        .then(() => {
          gettextCatalog.setCurrentLanguage(languageCode);
          Notification.addSuccessMessage(gettextCatalog.getString('User settings updated'));
          window.location.reload();
          return _load(languageCode)
        })
        .catch((error) => {
          Notification.addErrorMessage({text: error});
        });
    } else {
      return _load(languageCode)
        .catch((error) => {
          Notification.addErrorMessage({text: error});
        });
    }
  }

  function init(language) {
    var user = UserSession.get();

    // check for browser language
    if(language) {
      return set(language, true);
    } else if (user && user.language) {
      return set(user.language, true);
    } else {
      return set(settings.defaultLanguage, true);
    }
  }
});


