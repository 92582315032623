'use strict';

var subscriptionModule = angular.module('t2g.subscription', [
  'ui.router',
  require('t2g.common.services.notificationService').name,
  require('t2g.common.services.sessionStorage').name,
  require('t2g.common.services.billwerkService').name
]);

module.exports = subscriptionModule;

subscriptionModule.controller('SubscriptionCtrl', require('./SubscriptionCtrl'));
subscriptionModule.controller('BillingDetailsCtrl', require('./BillingDetailsCtrl'));
subscriptionModule.controller('PaymentDetailsCtrl', require('./PaymentDetailsCtrl'));
subscriptionModule.controller('CancelAccountModalCtrl', require('./CancelAccountModalCtrl'));
subscriptionModule.controller('VatIDModalCtrl', require('./VatIDModalCtrl'));
subscriptionModule.controller('FinalizeCtrl', require('./FinalizeCtrl'));
subscriptionModule.controller('InvoiceListCtrl', require('./InvoiceListCtrl'));

subscriptionModule.config(function ($stateProvider) {

  $stateProvider
    .state('private.main.subscription', {
      url: '/subscription/?:openTab',
      params: {
        openTab: {
          dynamic: true
        }
      },
      title: 'Subscription',
      templateUrl: 'https://cdn.termin2go.com/app/app/modules/subscription/SubscriptionViewNew.html?v=3.80.3',
      controller: 'SubscriptionCtrl as SubscriptionCtrl',
      resolve: {
        billwerk: function (BillwerkService) {
          return BillwerkService.load();
        },
        subscription: function (SubscriptionDA) {
          return SubscriptionDA.get();
        },
        office: function (OfficeDA) {
          return OfficeDA.get();
        },
        employees: function (ResourceDA) {
          return ResourceDA.list('employee')
            .then(function (response) {
              return response.resources;
            });
        },
        countries: function (CountryConfig) {
          return CountryConfig.getCountries();
        }
      }
    })
    .state('private.main.billingDetails', {
      url: '/subscription/billingDetails?voucher',
      title: 'Billing Details',
      templateUrl: 'https://cdn.termin2go.com/app/app/modules/subscription/BillingDetailsView.html?v=3.80.3',
      controller: 'BillingDetailsCtrl as BillingDetailsCtrl',
      resolve: {
        billwerk: function (BillwerkService) {
          return BillwerkService.load();
        },
        subscription: function (SubscriptionDA) {
          return SubscriptionDA.get();
        },
        countries: function (CountryConfig) {
          return CountryConfig.getCountries();
        },
        office: function (OfficeDA) {
          return OfficeDA.get();
        }
      }
    })
    .state('private.main.paymentDetails', {
      url: '/subscription/paymentDetails',
      title: 'Payment Details',
      templateUrl: 'https://cdn.termin2go.com/app/app/modules/subscription/PaymentDetailsView.html?v=3.80.3',
      controller: 'PaymentDetailsCtrl as PaymentDetailsCtrl',
      resolve: {
        billwerk: function (BillwerkService) {
          return BillwerkService.load();
        },
        subscription: function (SubscriptionDA) {
          return SubscriptionDA.get();
        },
        office: function (OfficeDA) {
          return OfficeDA.get();
        }
      }
    })
    .state('private.main.finalize', {
      url: '/subscription/finalize',
      title: 'Payment Finalize',
      templateUrl: 'https://cdn.termin2go.com/app/app/modules/subscription/FinalizeView.html?v=3.80.3',
      controller: 'FinalizeCtrl as FinalizeCtrl',
      resolve: {
        billwerk: function (BillwerkService) {
          return BillwerkService.load();
        }
      }
    })
    .state('private.main.invoices', {
      url: '/subscription/invoices',
      title: 'Invoices',
      templateUrl: 'https://cdn.termin2go.com/app/app/modules/subscription/InvoiceListView.html?v=3.80.3',
      controller: 'InvoiceListCtrl as InvoiceListCtrl',
      resolve: {
        invoices: function (SubscriptionDA) {
          return SubscriptionDA.getInvoices();
        }
      }
    });
});
