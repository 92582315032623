module.exports = {
  active: 'calendar',
  calendar: {
    order: true,
    zoom: true,
    privateNoteTitles: true
  },
  booking: {
    appBookable: true,
    coursesBookable: true,
    address: true,
    birthday: true,
    maxServices: true,
    appointmentCluster: true,
    dynamicSlotLength: true,
    slotLength: true,
    slotStart: true,
    appointmentPause: true,
    currency: true,
    minLeadtime: true,
    bookAdvance: true,
    cancelLimit: true,
    acceptBooking: true,
    serviceGroupCollapsing: true,
    disablePriceColumn: true,
    disableEmployeeChooser: true,
    showBookAdditionalBtn: true,
    redirectAfterBooking: true,
    hideDuration: true
  },
  email: {
    showCustomLogo: true,
    globalMailAddressEnabled: true,
    globalMailAddress: true,
    employeeMailAddressEnabled: true,
    sendAppointmentChangeNotificationMails: true,
    smtp: true
  },
  reminder: {
    reminderMail: true,
    reminderMailTime: true,
    reminderSMSTime: true,
    reminderSMSChargedAlert: true,
    reminderSMSText: true
  },
  legal: {
    checkConditions: true,
    conditions: true,
    ownDataPrivacyTerms: true,
    dataPrivacyTermsLink: true
  },
  accounting: {
    paymentMethods: true,
    cashProvider: true
  },
  onlinePayments: {
    stripe: true,
    mode: true,
  },
  covidTestResult: {
    enabled: true,
    provider: true,
    healthDepartmentAddress: true
  },
  employeePIN: true
};
