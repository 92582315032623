'use strict';

var calendarModule = angular.module('t2g.calendar', [
  'gettext',
  'ui.router',
  'angularResizable',
  require('t2g.common.services.modalService').name,
  require('t2g.common.data-access.calendar').name
]);

module.exports = calendarModule;

calendarModule.controller('AppointmentCtrl', require('./AppointmentCtrl'));
calendarModule.controller('AppointmentModalBaseCtrl', require('./AppointmentModalBaseCtrl'));
calendarModule.controller('AppointmentsCtrl', require('./AppointmentsCtrl'));
calendarModule.controller('CalendarContainerCtrl', require('./CalendarContainerCtrl'));
calendarModule.controller('CalendarCtrl', require('./CalendarCtrl'));
calendarModule.controller('CourseAppointmentModalCtrl', require('./CourseAppointmentModalCtrl'));
calendarModule.controller('CustomerAppointmentModalCtrl', require('./CustomerAppointmentModalCtrl'));
calendarModule.controller('DeleteSeriesModalCtrl', require('./DeleteSeriesModalCtrl'));
calendarModule.controller('PrivateAppointmentModalCtrl', require('./PrivateAppointmentModalCtrl'));
calendarModule.controller('CWALegalCtrl', require('./CWALegalCtrl'));
calendarModule.directive('absentTimes', require('./absentTimesDirective'));
calendarModule.directive('appointment', require('./appointmentDirective'));
calendarModule.directive('appointments', require('./appointmentsDirective'));
calendarModule.directive('calendar', require('./calendarDirective'));
calendarModule.directive('calendarList', require('./calendarListDirective'));
calendarModule.directive('fixedTimeLine', require('./fixedTimeLineDirective'));
calendarModule.directive('timeline', require('./timelineDirective'));
calendarModule.factory('PixelTimeService', require('./PixelTimeCalcServce'));
calendarModule.factory('AppointmentDragAndResizeState', require('./AppointmentDragAndResizeState'));
calendarModule.factory('CalendarSettings', require('./CalendarSettings'));

calendarModule.config(function ($stateProvider) {
  $stateProvider
    .state('private.main.cwa-legal', {
      url: '/cwa-legal',
      templateUrl: 'https://cdn.termin2go.com/app/app/modules/calendar/CWALegalView.html?v=3.80.3',
      controller: 'CWALegalCtrl as CWALegalCtrl',
      title: 'Corona Warn App Datenschutzhinweise'
    })
    .state('private.main.calendar', {
      url: '/calendar?clear/:resource/:view/:date',
      templateUrl: 'https://cdn.termin2go.com/app/app/modules/calendar/CalendarContainer.html?v=3.80.3',
      controller: 'CalendarContainerCtrl as CalendarContainerCtrl',
      title: 'Calendar',
      onEnter: function ($stateParams,
                         LocalStorage) {
        LocalStorage.set('calendarView', {
          date: $stateParams.date,
          resource: $stateParams.resource,
          view: $stateParams.view
        });
      },
      resolve: {
        office: function (OfficeDA, CalendarSettings) {
          return OfficeDA
            .get()
            .then((office) => {
              let root = document.documentElement;
              const zoom = office.settings.calendar.zoom || 1;
              root.style.setProperty('--hour-height', `${zoom * 72}px`);
              CalendarSettings.set('hourHeight', zoom * 72);

              return office;
            });
        },
        calendar: function (CalendarDA, CalendarSettings, $stateParams) {
          var start;
          var view = $stateParams.view;
          var end;

          if (view === 'week') {
            start = moment($stateParams.date).startOf('week').format('YYYY-MM-DD');
            end = moment(start).add(6, 'days').format('YYYY-MM-DD');
          } else if (view === '4days') {
            start = moment($stateParams.date).format('YYYY-MM-DD');
            end = moment(start).add(3, 'days').format('YYYY-MM-DD');
          } else {
            start = $stateParams.date;
            end = start;
          }
          return CalendarDA
            .get(start, end, $stateParams.resource)
            .then((response) => {
              let root = document.documentElement;
              const start = typeof response.min === 'number' ? response.min : 7;
              const end = typeof response.max === 'number' ? response.max : 23;
              const hours = end - start;
              root.style.setProperty('--hours', hours.toString());

              CalendarSettings.set('offset', response.min);
              CalendarSettings.set('end', response.max);
              return response.dates;
            });
        },
        resources: function (gettextCatalog, ResourceDA) {
          var resources = [];
          var groupedResources = {
            employees: [],
            rooms: []
          };

          return ResourceDA
            .list()
            .then(function (response) {
              _.forEach(response.resources, function (resource) {
                if (resource.type === 'room') {
                  groupedResources.rooms.push({
                    _id: resource._id,
                    type: gettextCatalog.getString('Rooms'),
                    label: resource.fullname
                  });
                } else {
                  groupedResources.employees.push({
                    _id: resource._id,
                    type: gettextCatalog.getString('Employees'),
                    label: resource.fullname
                  });
                }
              });

              if (groupedResources.employees.length > 0) {
                resources.push({
                  _id: 'all-employees',
                  type: gettextCatalog.getString('Employees'),
                  label: gettextCatalog.getString('- All Employees -')
                });
                resources = resources.concat(groupedResources.employees);
              }

              if (groupedResources.rooms.length > 0) {
                resources.push({
                  _id: 'all-rooms',
                  type: gettextCatalog.getString('Rooms'),
                  label: gettextCatalog.getString('- All Rooms -')
                });
                resources = resources.concat(groupedResources.rooms);
              }

              return resources;
            });
        }
      },
      params: {
        resource: function (LocalStorage) {
          var savedView = LocalStorage.get('calendarView');

          if (savedView && savedView.resource) {
            return savedView.resource;
          } else {
            return 'all-employees';
          }
        },
        date: function (LocalStorage) {
          var savedView = LocalStorage.get('calendarView');

          if (savedView && savedView.date) {
            return savedView.date;
          } else {
            return moment().startOf('week').format('YYYY-MM-DD');
          }
        },
        view: function (LocalStorage) {
          var savedView = LocalStorage.get('calendarView');

          if (savedView && savedView.view) {
            return savedView.view;
          } else {
            return 'week';
          }
        }
      }
    });
});
