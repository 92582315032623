/**
 *
 * @param $scope
 * @param $state
 * @param messagesResolve
 * @constructor
 */
var MessageBoxCtrl = function ($timeout,
                               $scope,
                               $state,
                               $stateParams,
                               gettextCatalog,
                               DatepickerSettings,
                               MobileDetectService,
                               messagesResolve) {
    this.$scope = $scope;
    this.$state = $state;
    this.box = $stateParams.box;
    this.messages = messagesResolve.messages;
    this.pagination = messagesResolve.pagination;
    this.MobileDetectService = MobileDetectService;
    this.boxTitle = gettextCatalog.getString(this.box);
    this.date = $stateParams.date ? moment($stateParams.date).toDate() : new Date();
    this.dtpOptions = DatepickerSettings.default;

    $scope.messages = messagesResolve.messages;
};


/**
 * open message with specific id
 * @param message
 */
MessageBoxCtrl.prototype.openMessage = function (message) {
    var allowedTypes = {
        mail: true,
        sms: true,
    };

    if (message.type && allowedTypes[message.type]) {
        if(this.MobileDetectService.isMobile) {
            this.$state.go('private.main.messages-mobile.' + message.type, {messageId: message._id, box: this.box});
        } else {
            this.$state.go('private.main.messages.' + message.type, {messageId: message._id, box: this.box});
        }
    }
};

MessageBoxCtrl.prototype.changePage = function(pageChange) {
    const page = this.pagination.currentPage + pageChange;

    this.$state.go('private.main.messages', {box: this.box, page});
};

module.exports = MessageBoxCtrl;
