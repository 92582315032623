'use strict';

var accountingModule = angular.module('t2g.accounting', [
    'gettext',
    'ui.router',
    require('t2g.common.services.modalService').name,
    require('t2g.common.services.notificationService').name

]);

module.exports = accountingModule;

accountingModule.controller('CashModalCtrl', require('./CashModalCtrl'));
accountingModule.controller('CashEntryModalCtrl', require('./CashEntryModalCtrl'));
accountingModule.controller('CashBookCtrl', require('./CashBookCtrl'));
accountingModule.controller('CashEntryCancelModalCtrl', require('./CashEntryCancelModalCtrl'));
accountingModule.controller('OpenDayModalCtrl', require('./OpenDayModalCtrl'));

accountingModule.config(function($stateProvider) {
    $stateProvider
        .state('private.main.accountingCashBook', {
            url: '/cashBook/:date',
            title: 'Cash Book',
            templateUrl: 'https://cdn.termin2go.com/app/app/modules/accounting/CashBookView.html?v=3.80.3',
            controller: 'CashBookCtrl as CashBookCtrl',
            resolve: {
                cashBook: function($stateParams, $state, AccountingDA) {
                    var date = $stateParams.date ? $stateParams.date : moment().format('YYYY-MM-DD');
                    return AccountingDA
                        .getCashBook(date)
                        .catch(function(error){
                            return error.data;
                        });
                },
                office: function (OfficeDA) {
                    return OfficeDA.get();
                }
            }
        });
});
